import {
  IPurchasable,
  IPurchasablePaginatedResponse,
  getCleanedAndSortedPurchasables,
} from "@smartrr/shared/entities/Purchasable";
import { AppReqResponse } from "@smartrr/shared/req";
import { delay } from "@smartrr/shared/utils/delay";
import {
  MAX_PAGE_SIZE,
  PaginationResult,
  serializePaginatedQueryValues,
} from "@smartrr/shared/utils/paginatedQuery";
import { flatten } from "lodash";

import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

import { GeneralSmartrrDispatch } from "../../app/_state/typedVendorRedux";

const CHUNK_SIZE = 5;
const DELAY_TIME = 1000;

export const fetchRemainingPurchasables = async ({
  dispatch,
  purchasables,
}: {
  dispatch: GeneralSmartrrDispatch;
  purchasables: IPurchasablePaginatedResponse;
}) => {
  const { totalPages } = purchasables;

  // If no additional pages need to be loaded then set isLoadingAdditionally to false early
  if (totalPages === 1) {
    dispatch({
      type: "LOADED_ADDITIONAL_PURCHASABLES",
      payload: {
        purchasables,
      },
    });
  }

  const purchasablePromises: AppReqResponse<PaginationResult<IPurchasable>>[][] = [];
  for (let chunkIndex = 0; chunkIndex < Math.ceil(totalPages - 1); chunkIndex += CHUNK_SIZE) {
    //this piece generates and populates array from 0 to N
    //removing the first page batch from total and starting from pagenumber === 1
    //to exclude the first page that was fetched separately
    let totalCallsForChunk = totalPages - chunkIndex > CHUNK_SIZE ? CHUNK_SIZE : totalPages - chunkIndex;

    if (chunkIndex === 0) {
      totalCallsForChunk -= 1;
    }

    purchasablePromises.push(
      await Promise.all(
        [...new Array(totalCallsForChunk).keys()].map((item, index) => {
          return typedFrontendVendorApi.getReq("/purchasable", {
            query: serializePaginatedQueryValues({
              pageSize: MAX_PAGE_SIZE,
              //index + 1 is needed to exclude the first page
              pageNumber: chunkIndex === 0 ? chunkIndex + index + 1 : chunkIndex + index,
            }),
          });
        })
      )
    );
    await delay(DELAY_TIME);
  }

  //go through the promises to store data in the purchasables variable
  flatten(purchasablePromises).map((promise, index) => {
    if (promise.type === "success") {
      //reassign default purchasables values with the ones from the request
      //only changing the data property, everything else in the request is the same
      purchasables.data = [...purchasables.data, ...promise.body.data];
    }
  });

  //update the redux state with all the data once
  dispatch({
    type: "LOADED_ADDITIONAL_PURCHASABLES",
    payload: {
      purchasables: { ...purchasables, data: getCleanedAndSortedPurchasables(purchasables.data) },
    },
  });
};
