import { jwtPropertyName, shopUrlPropertyName } from "@smartrr/shared/constants";
import { SmartrrThunkAction } from "@vendor-app/app/_state/typedVendorRedux";
import { vendorAsyncDispatch } from "@vendor-app/app/_state/vendorAsyncDispatch";
import { SmartRRWebSocket } from "@vendor-app/app/SmartRRWebSocket";
import { ensurePublicAppKey } from "@vendor-app/utils/getAppBridgeInstance";
import { getAppBridgeSessionToken } from "@vendor-app/utils/getAppBridgeSessionToken";
import { isInIFrame } from "@vendor-app/utils/isInIframe";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";
import { updateQueryParams } from "@smartrr/shared/utils/windowLocationUtils";
import { fetchRemainingPurchasables } from "@vendor-app/utils/fetchRemainingPurchasables";
import {
  getCleanedAndSortedPurchasables,
  IPurchasablePaginatedResponse,
} from "@smartrr/shared/entities/Purchasable";

export const logout = (): SmartrrThunkAction<"LOADING_USER" | "LOADED_USER"> => async dispatch => {
  // remove shop from the url if it exists, so they aren't automatically
  // logged back in by the authentication redirect logic in useRequiredAuthRedirects.
  updateQueryParams({ [shopUrlPropertyName]: null });

  return vendorAsyncDispatch(
    typedFrontendVendorApi.getReq("/auth/logout", { credentials: "include" }),
    () =>
      dispatch({
        type: "LOADING_USER",
        payload: undefined,
      }),
    () =>
      dispatch({
        type: "LOADED_USER",
        payload: {
          user: null,
          [jwtPropertyName]: null,
          activeOrganizationId: undefined,
        },
      }),
    () => {
      return dispatch({
        type: "LOADED_USER",
        payload: {
          user: null,
          [jwtPropertyName]: null,
          activeOrganizationId: undefined,
        },
      });
    }
  );
};

export const loadUser =
  (): SmartrrThunkAction<"LOADING_USER" | "LOADED_VENDOR_PORTAL" | "LOADED_PURCHASABLES"> =>
  async (dispatch, getState) => {
    const shopUrl = getState().shopifyStoreData[shopUrlPropertyName];

    if (shopUrl) {
      await ensurePublicAppKey(shopUrl);
    }

    const query = {
      [shopUrlPropertyName]: shopUrl,
    };

    const headers = {
      ...(isInIFrame
        ? {
            "x-shopify-session-token": await getAppBridgeSessionToken(),
          }
        : {}),
    };

    return vendorAsyncDispatch(
      typedFrontendVendorApi.getReq("/auth/portal-session", {
        query,
        headers,
      }),
      () =>
        dispatch({
          type: "LOADING_USER",
          payload: undefined,
        }),
      () =>
        dispatch({
          type: "LOADED_VENDOR_PORTAL",
          payload: {
            user: null,
            auth_token: null,
            activeOrganizationId: null,
            accountPlan: null,
            organization: null,
          },
        }),
      ({ user, accountPlan, organization, [jwtPropertyName]: authToken, purchasables: purchasablesRes }) => {
        // choose active organization
        const newOrganizations = (user?.vendorOrgRoles || []).map(orgRole => orgRole.organization);
        let nextActiveOrganizationId = getState().vendorOrganizations.activeOrganizationId;
        if (!nextActiveOrganizationId) {
          try {
            const organizationMatchingShop =
              (shopUrl &&
                newOrganizations.find(({ myShopifyDomain }) => myShopifyDomain && myShopifyDomain === shopUrl)) ||
              null;
            nextActiveOrganizationId = organizationMatchingShop?.id || null;
          } catch {}
        }

        if (!nextActiveOrganizationId) {
          nextActiveOrganizationId = newOrganizations.length === 1 ? newOrganizations[0].id : null;
        }

        if (purchasablesRes) {
          const purchasables: IPurchasablePaginatedResponse = {
            ...purchasablesRes,
            data: getCleanedAndSortedPurchasables(purchasablesRes.data),
          };

          dispatch({
            type: "LOADED_PURCHASABLES",
            payload: {
              purchasables,
            },
          });

          fetchRemainingPurchasables({ dispatch, purchasables });
        }

        return dispatch({
          type: "LOADED_VENDOR_PORTAL",
          payload: {
            user,
            auth_token: authToken ?? null,
            activeOrganizationId: nextActiveOrganizationId ?? null,
            accountPlan: accountPlan ?? null,
            organization: organization ?? null,
          },
        });
      }
    );
  };

export const streamShopifySubscriptionContractsToDb =
  (
    orgId: string,
    force = false,
    reverse = false
  ): SmartrrThunkAction<
    | "STREAMING_SHOPIFY_SUBSCRIPTION_CONTRACT_DATA"
    | "ERROR_STREAMING_SHOPIFY_SUBSCRIPTION_CONTRACT_DATA"
    | "STREAMING_SHOPIFY_SUBSCRIPTION_CONTRACT_DATA_ITEM"
    | "STREAMING_SHOPIFY_SUBSCRIPTION_CONTRACT_DATA_COMPLETE"
  > =>
  async dispatch =>
    vendorAsyncDispatch<
      | "STREAMING_SHOPIFY_SUBSCRIPTION_CONTRACT_DATA"
      | "ERROR_STREAMING_SHOPIFY_SUBSCRIPTION_CONTRACT_DATA"
      | "STREAMING_SHOPIFY_SUBSCRIPTION_CONTRACT_DATA_COMPLETE",
      {}
    >(
      new Promise(async resolve => {
        const topic = (await SmartRRWebSocket.create(orgId)).createTopicListener(
          "/organization/:orgId/sync/contracts",
          ({ done, count }) => {
            if (done) {
              resolve({
                type: "success",
                body: {},
              });
            } else {
              console.log(`${count} contract(s) uploaded`);
            }
          }
        );
        await topic.send({ orgId, force, reverse });
      }),
      () =>
        dispatch({
          type: "STREAMING_SHOPIFY_SUBSCRIPTION_CONTRACT_DATA",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_STREAMING_SHOPIFY_SUBSCRIPTION_CONTRACT_DATA",
          payload: {
            errorMessage: failure.message,
          },
        }),
      () =>
        dispatch({
          type: "STREAMING_SHOPIFY_SUBSCRIPTION_CONTRACT_DATA_COMPLETE",
          payload: undefined,
        })
    );

export const streamRechargeCustomersToDb =
  (
    orgId: string,
    force = false,
    active = false
  ): SmartrrThunkAction<
    | "STREAMING_RECHARGE_SUBSCRIPTION_DATA"
    | "STREAMING_RECHARGE_SUBSCRIPTION_DATA_COMPLETE"
    | "STREAMING_RECHARGE_SUBSCRIPTION_DATA_ITEM"
    | "ERROR_STREAMING_RECHARGE_SUBSCRIPTION_DATA"
  > =>
  async dispatch =>
    vendorAsyncDispatch<
      | "STREAMING_RECHARGE_SUBSCRIPTION_DATA"
      | "STREAMING_RECHARGE_SUBSCRIPTION_DATA_COMPLETE"
      | "ERROR_STREAMING_RECHARGE_SUBSCRIPTION_DATA",
      {}
    >(
      new Promise(async resolve => {
        const topic = (await SmartRRWebSocket.create(orgId)).createTopicListener(
          "/organization/:orgId/sync/recharge",
          ({ done, count }) => {
            if (done) {
              resolve({
                type: "success",
                body: {},
              });
            } else {
              console.log(`${count} customer(s) uploaded`);
            }
          }
        );
        await topic.send({ orgId, force, active });
      }),
      () =>
        dispatch({
          type: "STREAMING_RECHARGE_SUBSCRIPTION_DATA",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_STREAMING_RECHARGE_SUBSCRIPTION_DATA",
          payload: {
            errorMessage: failure.message,
          },
        }),
      () =>
        dispatch({
          type: "STREAMING_RECHARGE_SUBSCRIPTION_DATA_COMPLETE",
          payload: undefined,
        })
    );

export const streamShopifyOrdersToDb =
  (
    orgId: string,
    force = false
  ): SmartrrThunkAction<
    | "STREAMING_SHOPIFY_ORDERS_DATA"
    | "ERROR_STREAMING_SHOPIFY_ORDERS_DATA"
    | "STREAMING_SHOPIFY_ORDERS_DATA_ITEM"
    | "STREAMING_SHOPIFY_ORDERS_DATA_COMPLETE"
  > =>
  async dispatch => {
    return vendorAsyncDispatch<
      | "STREAMING_SHOPIFY_ORDERS_DATA"
      | "ERROR_STREAMING_SHOPIFY_ORDERS_DATA"
      | "STREAMING_SHOPIFY_ORDERS_DATA_COMPLETE",
      {}
    >(
      new Promise(async resolve => {
        const topic = (await SmartRRWebSocket.create(orgId)).createTopicListener(
          "/organization/:orgId/sync/orders",
          ({ done, count }) => {
            if (done) {
              resolve({
                type: "success",
                body: {},
              });
            } else {
              console.log(`${count} orders(s) uploaded`);
            }
          }
        );
        await topic.send({ orgId, force });
      }),
      () =>
        dispatch({
          type: "STREAMING_SHOPIFY_ORDERS_DATA",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_STREAMING_SHOPIFY_ORDERS_DATA",
          payload: {
            errorMessage: failure.message,
          },
        }),
      () =>
        dispatch({
          type: "STREAMING_SHOPIFY_ORDERS_DATA_COMPLETE",
          payload: undefined,
        })
    );
  };

export const streamShopifyProductsToDb =
  (
    orgId: string,
    force = false
  ): SmartrrThunkAction<
    | "STREAMING_SHOPIFY_PRODUCT_DATA"
    | "ERROR_STREAMING_SHOPIFY_PRODUCT_DATA"
    | "STREAMING_SHOPIFY_PRODUCT_DATA_ITEM"
    | "STREAMING_SHOPIFY_PRODUCT_DATA_COMPLETE"
  > =>
  async dispatch => {
    return vendorAsyncDispatch<
      | "STREAMING_SHOPIFY_PRODUCT_DATA"
      | "ERROR_STREAMING_SHOPIFY_PRODUCT_DATA"
      | "STREAMING_SHOPIFY_PRODUCT_DATA_COMPLETE",
      {}
    >(
      new Promise(async resolve => {
        const topic = (await SmartRRWebSocket.create(orgId)).createTopicListener(
          "/organization/:orgId/sync/products",
          ({ done, count }) => {
            if (done) {
              resolve({
                type: "success",
                body: {},
              });
            } else {
              console.log(`${count} product(s) uploaded`);
            }
          }
        );
        await topic.send({ orgId, force });
      }),
      () =>
        dispatch({
          type: "STREAMING_SHOPIFY_PRODUCT_DATA",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_STREAMING_SHOPIFY_PRODUCT_DATA",
          payload: {
            errorMessage: failure.message,
          },
        }),
      () =>
        dispatch({
          type: "STREAMING_SHOPIFY_PRODUCT_DATA_COMPLETE",
          payload: undefined,
        })
    );
  };
